import { useInViewport } from '@mantine/hooks'
import dynamic from 'next/dynamic'

const LanguageSwitchMenu = dynamic(() =>
  import('src/components/Footer/LanguageSwitchMenu').then((mod) => mod.LanguageSwitchMenu),
)

export const LanguageSwitch = () => {
  const { ref, inViewport } = useInViewport()

  return <li ref={ref}>{inViewport && <LanguageSwitchMenu />}</li>
}
